<script lang="ts">
	import EditorOverview from '$lib/components/_/landing/EditorOverview.svelte';
	import EnrichedTextFormatting from '$lib/components/_/landing/EnrichedTextFormatting.svelte';
	import Hero from '$lib/components/_/landing/Hero.svelte';
	import Codeblocks from '$lib/components/_/landing/Codeblocks.svelte';
	import CraftPremiumDocumentation from '$lib/components/_/landing/CraftPremiumDocumentation.svelte';
	import WebsiteStructure from '$lib/components/_/landing/WebsiteStructure.svelte';
	import Searchbar from '$lib/components/_/landing/Searchbar.svelte';
	import Deploying from '$lib/components/_/landing/Deploying.svelte';
	import Faq from '$lib/components/_/landing/Faq.svelte';
	import Pricing from '$lib/components/_/landing/Pricing.svelte';
	import Icon from '@iconify/svelte';

	export let data;
</script>

<svelte:head>
	<title>{data.context.title}</title>
	<meta name="description" content={data.context.og.description} />
	<meta property="og:title" content={data.context.og.title} />
	<meta property="og:description" content={data.context.og.description} />
	<meta property="og:url" content={data.context.og.url} />
	<meta property="og:type" content="website" />
	<meta property="og:image" content={data.context.og.image.url} />
	<meta property="og:image:alt" content={data.context.og.image.alt} />
	<meta property="og:image:type" content={data.context.og.image.type} />
	<meta property="og:image:width" content={data.context.og.image.width} />
	<meta property="og:image:height" content={data.context.og.image.height} />
	<meta name="twitter:title" content={data.context.og.title} />
	<meta name="twitter:description" content={data.context.og.description} />
	<meta name="twitter:card" content="summary_large_image" />
	<meta name="twitter:image" content={data.context.og.image.url} />
	<meta name="twitter:image:alt" content={data.context.og.image.alt} />
</svelte:head>

<main class="flex flex-col gap-20 sm:gap-24 pb-40">
	<Hero />
	<EditorOverview />
	<Deploying />
	<EnrichedTextFormatting />
	<Codeblocks />
	<CraftPremiumDocumentation />
	<WebsiteStructure />
	<Searchbar />
	<Faq />
	<Pricing />
</main>
