<script>
	import MockWindow from '$lib/components/mocks/MockWindow.svelte';
	import MockPhone from '$lib/components/mocks/MockPhone.svelte';
	import MockTablet from '$lib/components/mocks/MockTablet.svelte';
</script>

<div
	class="bg-gradient-to-b from-transparent to-base-300/50 sm:pb-8 shadow-2xl shadow-primary/5 border-b border-primary/10"
>
	<section class="mx-auto md:container">
		<div style:grid-area="text" class="px-4">
			<h2 id="mermaid" class="text-4xl sm:text-5xl font-bold scroll-mt-24">
				Craft <span class="underline decoration-accent/70 underline-offset-4 decoration-dotted"
					>Premium</span
				> Documentation
			</h2>
			<p class="mt-4 mb-6 text-xl lg:text-2xl font-semibold text-balance">No setup required</p>
			<div class="mb-6 prose text-balance font-medium">
				<p>
					There is everything you need, from <a
						href="https://docs.easypagego.com/editor/cards/"
						target="_blank"
						class="link link-hover">cards</a
					>
					to
					<a
						href="https://docs.easypagego.com/editor/accordions/"
						target="_blank"
						class="link link-hover">accordions</a
					>, from
					<a
						href="https://docs.easypagego.com/editor/callout-boxes/"
						target="_blank"
						class="link link-hover">callout boxes</a
					>
					to
					<a
						href="https://docs.easypagego.com/editor/images-and-videos/"
						target="_blank"
						class="link link-hover">images and videos</a
					>.
				</p>
				<p>
					
				</p>
				<p>
					It goes beyond, allowing you to harness the power of <a
						href="https://mermaid.js.org/"
						rel="noopener noreferrer"
						target="_blank"
						class="link link-accent link-hover">Mermaid</a
					>
					thanks to its dedicated component and its
					<strong class="text-xl font-semibold">19 available graphs</strong>.
				</p>
			</div>
		</div>
		<div style:grid-area="media">
			<div
				class="w-screen carousel carousel-center gap-0.5 md:grid md:grid-cols-2 md:w-full sm:gap-2 md:rounded-box"
			>
				<div
					class="carousel-item w-5/6 md:w-full bg-gradient-to-br from-primary to-secondary h-80 flex items-center justify-center"
				>
					<MockPhone class="shadow-xl shadow-white/5 rounded-box w-36 max-h-[90%] m-4 ring-success">
						<enhanced:img
							src="./callout-boxes.png?w=600&quality=100&lossless=false"
							sizes="(min-width: 1024px) 33.33vw, (min-width: 768px) 50vw, 100vw"
							alt="A screenshot of the dashboard representing a codeblock element."
							class="bg-cover w-full"
						/>
					</MockPhone>
				</div>
				<div
					class="carousel-item w-5/6 md:w-full bg-gradient-to-tl from-accent to-primary h-80 flex items-center justify-center"
				>
					<MockTablet class="2xl:hidden shadow-xl shadow-white/5 w-auto max-h-[90%] m-4 ring-secondary/80">
						<enhanced:img
							src="./cards.png?w=600&quality=100&lossless=false"
							sizes="(min-width: 1024px) 33.33vw, (min-width: 768px) 50vw, 100vw"
							alt="A screenshot of the dashboard representing a codeblock element."
							class="bg-cover w-full"
						/>
					</MockTablet>
				</div>
				<div
					class="carousel-item w-5/6 md:w-full bg-gradient-to-b from-accent/80 to-secondary h-80 flex items-center justify-center overflow-hidden"
				>
					<MockWindow class="shadow-xl shadow-white/5 max-h-[90%] w-fit m-4">
						<enhanced:img
							src="./video.png?w=600&quality=100&lossless=false"
							sizes="(min-width: 1024px) 33.33vw, (min-width: 768px) 50vw, 100vw"
							alt="A screenshot of the dashboard representing a codeblock element."
							class="bg-cover w-full rounded-b-box"
						/>
					</MockWindow>
				</div>
				<div
					class="carousel-item w-5/6 md:w-full bg-gradient-to-tl from-primary/80 to-secondary/90 h-80 flex items-center justify-center"
				>
					<MockTablet class="shadow-xl shadow-white/5 w-auto max-h-[90%] m-4 ring-accent">
						<enhanced:img
							src="./accordions.png?w=600&quality=100&lossless=false"
							sizes="(min-width: 1024px) 33.33vw, (min-width: 768px) 50vw, 100vw"
							alt="A screenshot of the dashboard representing a codeblock element."
							class="bg-cover w-full rounded-box"
						/>
					</MockTablet>
				</div>
			</div>
		</div>
		<div style:grid-area="video" class="px-4 z-10 relative">
			<MockWindow class="lg:absolute top-0 right-2 2xl:top-4 shadow-xl shadow-white/5 aspect-video">
				<video
					width="1280"
					height="960"
					autoplay
					loop
					playsinline
					muted
					class="bg-base-300 shadow-xl rounded-b-box"
				>
					<source src="/videos/components-md.mp4" type="video/mp4" />
					Your browser does not support the video tag.
				</video>
			</MockWindow>
		</div>
	</section>
</div>

<style lang="postcss">
	section {
		@apply grid grid-cols-1 auto-rows-auto gap-8;
		grid-template-areas: 'text' 'video' 'media';

		@media (min-width: 1024px) {
			@apply grid-cols-[1fr_2fr];
			grid-template-areas:
				'text video'
				'media media';
		}

		@media (min-width: 1280px) {
			@apply grid-cols-2;
			grid-template-areas:
				'text video'
				'media media';
		}
	}

	@media (min-width: 640px) {
		.carousel {
			&:hover .carousel-item:not(:hover) {
				filter: brightness(90%);
				transition: filter 300ms 30ms;
			}
		}
	}
</style>
