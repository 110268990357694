<script lang="ts">
	import { browser } from '$app/environment';
	import { cn } from '$lib/utils/style';
	import Icon from '@iconify/svelte';
	import { onMount } from 'svelte';

	const steps = [
		{
			text: 'Edit',
			icon: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="m14.06 9.02l.92.92L5.92 19H5v-.92zM17.66 3c-.25 0-.51.1-.7.29l-1.83 1.83l3.75 3.75l1.83-1.83a.996.996 0 0 0 0-1.41l-2.34-2.34c-.2-.2-.45-.29-.71-.29m-3.6 3.19L3 17.25V21h3.75L17.81 9.94z"/></svg>',
			active: false
		},
		{
			text: 'Configure',
			icon: '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 14 14"><path fill="currentColor" fill-rule="evenodd" d="M1 0a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1zm7 1a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v2.01a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1zm0 6a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1zm-8 3.99a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1V13a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1z" clip-rule="evenodd"/></svg>',
			active: false
		},
		{
			text: 'Build',
			icon: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M20 6a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6c0-1.11.89-2 2-2h6l2 2zm-.75 7H16V9h-2v4h-3.25L15 17.25"/></svg>',
			active: false
		},
		{
			text: 'Deploy',
			icon: '<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 256 256"><path fill="currentColor" d="M128 24a104 104 0 1 0 104 104A104.11 104.11 0 0 0 128 24m88 104a87.6 87.6 0 0 1-6.4 32.94l-44.7-27.49a15.9 15.9 0 0 0-6.24-2.23l-22.82-3.08a16.11 16.11 0 0 0-16 7.86h-8.72l-3.8-7.86a15.91 15.91 0 0 0-11-8.67l-8-1.73L96.14 104h16.71a16.06 16.06 0 0 0 7.73-2l12.25-6.76a16.6 16.6 0 0 0 3-2.14l26.91-24.34A15.93 15.93 0 0 0 166 49.1l-.36-.65A88.11 88.11 0 0 1 216 128m-176 0a87.5 87.5 0 0 1 8.54-37.8l11.34 30.27a16 16 0 0 0 11.62 10l21.43 4.61l3.81 7.92a16.09 16.09 0 0 0 14.4 9h1.48l-7.23 16.23a16 16 0 0 0 2.86 17.37l.14.14l19.61 20.2l-1.94 10A88.11 88.11 0 0 1 40 128"/></svg>',
			active: false
		}
	];
	let currentStep = 0;
	if (browser)
		onMount(() => {
			const interval = setInterval(() => {
				steps[currentStep].active = true;

				currentStep++;

				if (currentStep >= steps.length) {
					clearInterval(interval);
					setTimeout(() => {
						currentStep++;
					}, 300);
				}
			}, 300);
		});
</script>

<section class="container mx-auto px-4 sm:pb-16 md:pb-20 pt-10 sm:pt-12 relative">
	<div class="grid grid-cols-1 lg:grid-cols-2 gap-8">
		<div>
			<h1
				class="mb-8 font-black text-4xl sm:text-6xl text-center tracking-wide mx-auto md:text-left"
			>
				<div>
					Build <span
						class="bg-clip-text text-transparent bg-gradient-to-br from-secondary to-primary"
						>Docs</span
					> Websites.
				</div>
				<div>
					The <span
						class="bg-clip-text text-transparent bg-gradient-to-tr from-secondary to-primary"
						>Easy</span
					>
					Way.
				</div>
			</h1>

			<div class="relative">
				<div
					class={cn(
						'pointer-events-none',
						'light absolute inset-0',
						'transition-opacity duration-1000 ease-out',
						currentStep > steps.length ? 'opacity-100' : 'opacity-0'
					)}
				/>
				<ul class="w-full timeline timeline-vertical">
					{#each steps as { text, icon, active }, index}
						{@const even = index % 2 == 0}
						<li class={cn(index < steps.length - 1 ? "grid-rows-[1fr_4fr_1fr]" : "grid-rows-[1fr_4fr_2fr]")}>
							{#if index > 0}
								<hr
									class={cn(
										'transition-colors duration-300 ease-in',
										active ? 'bg-primary' : 'bg-base-100',
										'rounded-none'
									)}
								/>
							{/if}
							<div
								class={cn(
									'border-2 border-primary',
									'transition duration-300 ease-in-out',
									currentStep > index
										? `${even ? '-translate-x-4' : 'translate-x-4'} opacity-100`
										: 'translate-x-0 opacity-0',
									even ? 'timeline-start' : 'timeline-end',
									'timeline-box bg-base-100/60 backdrop-blur-[1px] font-bold tracking-wide text-lg'
								)}
							>
								{text}
							</div>
							<div
								class={cn(
									'transition-all duration-300 ease-in',
									'grid place-content-center',
									active
										? 'bg-primary/30 border-primary text-primary shadow-md shadow-primary/10'
										: 'bg-base-100/20 border-base-100 text-base-content shadow-none',
									'timeline-middle w-11 h-11 p-1.5 border-2  rounded-full'
								)}
							>
								{@html icon}
								<!-- <Icon {icon} width="28" height="28" /> -->
							</div>
							<hr
								class={cn(
									'transition-colors duration-300 ease-in',
									active ? 'bg-primary' : 'bg-base-100',
									'rounded-none'
								)}
							/>
						</li>
					{/each}
				</ul>
				<div class="card-actions flex-col items-center">
					<a
						href="/#pricing"
						class={cn(
							'transition-colors duration-300 ease-in',
							currentStep > steps.length ? 'btn-primary' : 'btn-ghost',
							'btn btn-lg btn-wide'
						)}
					>
						<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 16 16"
							><path
								fill="currentColor"
								d="M8 16A8 8 0 1 1 8 0a8 8 0 0 1 0 16m3.031-12a4.38 4.38 0 0 0-3.097 1.283l-.23.229q-.235.235-.452.49H5.65a.88.88 0 0 0-.746.417l-.856 1.388a.377.377 0 0 0 .21.556l1.552.477l1.35 1.35l.478 1.553a.374.374 0 0 0 .555.21l1.389-.855a.88.88 0 0 0 .416-.746V8.747q.255-.216.49-.452l.23-.23A4.38 4.38 0 0 0 12 4.969v-.093A.876.876 0 0 0 11.124 4Zm-5.107 7.144h-.001a.809.809 0 0 0-1.33-.881c-.395.394-.564 1.258-.62 1.62a.12.12 0 0 0 .035.108a.12.12 0 0 0 .108.035c.362-.056 1.226-.225 1.62-.619a.8.8 0 0 0 .188-.263"
							/></svg
						>
						<span> Get Started </span>
					</a>
				</div>
			</div>
		</div>
		<div
			class={cn(
				'hidden lg:flex transition duration-500 ease-out',
				currentStep > steps.length
					? 'opacity-100 translate-y-0'
					: 'opacity-0 lg:opacity-100 translate-y-10 lg:translate-y-0',
				'flex-col items-center'
			)}
		>
			<!-- svelte-ignore a11y-media-has-caption -->
			<div
				class="aspect-video bg-base-300 w-full h-full rounded-box shadow-lg shadow-white/5 grid place-content-center"
			>
				<span class="text-4xl font-bold tracking-wide">Coming Soon</span>
			</div>
			<!-- <video
				width="1200"
				height="600"
				controls
				class="bg-base-300 shadow-xl shadow-white/5 rounded-box"
			>
				<source src="/videos/add-state-mermaid-graph.mp4" type="video/mp4" />
				Your browser does not support the video tag.
			</video> -->
			<p class="mt-4 flex items-center gap-2 font-bold tracking-wide text-lg">
				<Icon icon="ci:timer" width="24" height="24" class="text-primary" />
				<span> 1 minute video </span>
			</p>
			<p class="mt-1 font-medium">
				It tells you <span class="underline decoration-primary decoration-dotted underline-offset-2"
					>everything you need</span
				> to know!
			</p>
		</div>
	</div>
	<div
		aria-hidden="true"
		class="absolute top-0 left-0 right-0 h-screen w-[200vw] -translate-x-1/2 fade-bottom -z-50"
	>
		<svg class="" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
			<defs>
				<pattern id="dotPattern" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
					<circle class="fill-secondary/40" cx="5" cy="5" r="1"></circle></pattern
				>
			</defs>
			<rect x="0" y="0" width="100%" height="100%" fill="url(#dotPattern)"></rect>
		</svg>
	</div>
</section>

<style lang="postcss">
	.fade-bottom {
		-webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
		mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
	}

	.light {
		background: radial-gradient(
			circle at center 75%,
			oklch(0.753513 0.138989 232.661 / 0.1) 0%,
			rgba(255, 0, 0, 0) 50%
		);
		mask-image: linear-gradient(
			to bottom,
			rgba(0, 0, 0, 0.1),
			black 10%,
			black 90%,
			rgba(0, 0, 0, 0.1)
		);
		-webkit-mask-image: linear-gradient(
			to bottom,
			rgba(0, 0, 0, 0.1),
			black 10%,
			black 90%,
			rgba(0, 0, 0, 0.1)
		);
	}
</style>
