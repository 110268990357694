<script lang="ts">
	import Codeblock from '$lib/components/Codeblock.svelte';
	import { cn } from '$lib/utils/style';
	import Icon from '@iconify/svelte';
	import { bundledLanguages, bundledThemes, type BundledLanguage, type BundledTheme } from 'shiki';

	const js = `import Sdk from '@yourCompany/sdk';

const sdk = Sdk.initialize({ apiKey: process.env.API_KEY });

const users = await sdk.fetch({ resource: 'users' }).catch((error) => {
	console.error('Error performing action:', error);
	return [];
});
`;
	const python = `import os
from your_company import Sdk

sdk = Sdk.initialize(api_key=os.getenv('API_KEY'))

try:
    users = sdk.fetch(resource='users')
except Exception as error:
    # Log error and use fallback
    print('Error performing action:', error)
    users = []`;

	const go = `package main

import (
	"fmt"
	"os"
	"your_company/sdk/v1" sdk
)

func main() {
	apiKey := os.Getenv("API_KEY")
	s := sdk.Initialize(apiKey)

	users, err := s.Fetch("users")
	if err != nil {
		// Log error and use fallback
		fmt.Println("Error performing action:", err)
		users = []sdk.User{}
	}
}
`;

	const languages = Object.keys(bundledLanguages) as BundledLanguage[];
	let language: BundledLanguage | '' = '';
	$: included = language != '' && languages.includes(language);

	const themes = Object.keys(bundledThemes) as BundledTheme[];
	let themeJs: BundledTheme = 'material-theme-lighter';
	let themePython: BundledTheme = 'andromeeda';
	let themeGo: BundledTheme = 'dark-plus';
	function getRandomTheme() {
		const index = Math.floor(Math.random() * themes.length);
		return themes[index];
	}
</script>

<div
	class="bg-gradient-to-b from-transparent to-accent/10 pb-6 shadow-2xl shadow-accent/5 border-b border-primary/10 px-4 sm:pb-16 md:pb-20"
>
	<section class="container mx-auto">
		<div style:grid-area="text">
			<h2 id="codeblock" class="text-4xl sm:text-5xl font-bold scroll-mt-24">
				Aim for Better Documentation
			</h2>
			<p class="mt-4 mb-6 text-xl lg:text-2xl font-semibold text-balance">
				Unleash the full power of <a
					href="https://shiki.style/"
					rel="noopener noreferrer"
					target="_blank"
					class="link link-hover">Shiki</a
				>
				with the versatile
				<a
					class="font-bold link link-primary link-hover"
					href="https://docs.easypagego.com/editor/codeblocks/"
					target="_blank">Codeblock</a
				> component
			</p>
			<p class="mb-6 prose text-balance font-medium">
				Paste your code or write it directly in the editor. Customize <span
					class="underline decoration-accent decoration-dotted brightness-150">light</span
				>
				and
				<span class="underline decoration-accent/80 decoration-dotted brightness-110">dark</span>
				mode themes effortlessly.
			</p>

			<div class="mb-8">
				<div class="text-2xl">
					<strong class="text-4xl">
						{languages.length}
					</strong>
					available languages.
				</div>
				<label class="form-control">
					<div class="label">
						<span class="label-text text-sm">Is the language you look for here?</span>
					</div>
					<input
						type="text"
						placeholder="Type the name of the language"
						bind:value={language}
						class={cn('input input-bordered border-2 w-full sm:max-w-xs', included && 'input-success')}
						list="languages"
						autocomplete="off"
					/>
					<datalist id="languages">
						{#each languages as language}
							<option value={language} />
						{/each}
					</datalist>
				</label>
			</div>

			<div class="flex items-baseline gap-6">
				<div class="text-2xl">
					<strong class="text-4xl">{themes.length}</strong>
					available themes
				</div>
				<button
					on:click={() => {
						themeJs = getRandomTheme();
						themePython = getRandomTheme();
						themeGo = getRandomTheme();
					}}
					type="button"
					class={cn(
						'hidden md:flex items-center gap-2',
						'btn hover:btn-accent',
						included && 'btn-success'
					)}
				>
					<span>Randomize</span>
					<Icon icon="ri:dice-fill" width="20" height="20" />
				</button>
			</div>
		</div>
		<div style:grid-area="media" class="relative">
			<div
				class="w-full overflow-hidden rounded-box grid md:grid-cols-2 md:grid-rows-[min-content_min-content] gap-4"
			>
				<Codeblock theme={themeJs} code={js} lang="js" class="hidden md:block shadow-inner" />
				<Codeblock theme={themePython} code={go} lang="go" class="row-span-2 md:w-auto" />
				<Codeblock theme={themeGo} code={python} lang="python" class="hidden md:block" />
				<div
					class="absolute -bottom-8 left-1/2 -translate-x-1/2 tooltip tooltip-top md:hidden"
					data-tip="Change Theme"
				>
					<button
						on:click={() => {
							themeJs = getRandomTheme();
							themePython = getRandomTheme();
							themeGo = getRandomTheme();
						}}
						type="button"
						class={cn('btn btn-circle btn-lg shadow-xl', included && 'btn-success')}
					>
						<Icon icon="ri:dice-fill" width="28" height="28" />
						<span class="sr-only">Randomize Theme</span>
					</button>
				</div>
			</div>
		</div>
		<div style:grid-area="video" class="mt-4 grid place-content-center">
			<div class="w-fit mockup-window bg-base-300 shadow-xl shadow-white/5">
				<video
					width="1280"
					height="960"
					autoplay
					loop
					playsinline
					muted
					class="transition-shadow bg-base-300"
				>
					<source src="/videos/codeblocks-md.mp4" type="video/mp4" media="(max-width: 768px)" />
					<source src="/videos/codeblocks-lg.mp4" type="video/mp4" media="(min-width: 769px)" />
					Your browser does not support the video tag.
				</video>
			</div>
		</div>
	</section>
</div>

<style lang="postcss">
	section {
		@apply grid grid-cols-1 auto-rows-auto gap-8;
		grid-template-areas: 'text' 'media' 'video';

		@media (min-width: 1280px) {
			@apply grid-cols-[3fr_2fr];
			grid-template-areas:
				'text video'
				'media media';
		}
	}
</style>
