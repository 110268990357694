<script lang="ts">
	import { cn } from '$lib/utils/style';
	let className = '';
	export { className as class };
</script>

<div class={cn('border-4 border-neutral ring-primary/80 ring-2 overflow-hidden shadow-xl shadow-base-100/20 relative bg-base-200', className)}>
	<div class="h-2 flex flex-colbg-black">
		<div class="w-2/5 mx-auto bg-neutral h-full rounded-b-box">
		</div>
	</div>
	<slot />
</div>
