<script lang="ts">
	import { cn } from '$lib/utils/style';

	let className = '';
	export { className as class };
	export let classBar = 'rounded-t-box';
</script>

<div class={cn('rounded-box', className)}>
	<div
		aria-hidden="true"
		class={cn('w-full bg-base-300 h-8 sm:h-10 flex items-center gap-2 pl-4', classBar)}
	>
		<div class="w-2 h-2 sm:w-3 sm:h-3 bg-error rounded-full shadow-inner"></div>
		<div class="w-2 h-2 sm:w-3 sm:h-3 bg-warning rounded-full shadow-inner"></div>
		<div class="w-2 h-2 sm:w-3 sm:h-3 bg-success rounded-full shadow-inner"></div>

		{#if 'title' in $$slots}
			<div class="ml-auto mr-4 text-sm sm:text-base">
				<slot name="title" />
			</div>
		{/if}
	</div>
	<slot />
</div>
