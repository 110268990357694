<script lang="ts">
	import MockPhone from '$lib/components/mocks/MockPhone.svelte';
	import MockTablet from "$lib/components/mocks/MockTablet.svelte";
	import Icon from '@iconify/svelte';
</script>

<div
	class="bg-gradient-to-b from-transparent to-base-300/30 pb-6 shadow-2xl shadow-primary/5 border-b border-primary/10 px-4 sm:pb-16 md:pb-20"
>
	<section class="container mx-auto">
		<div style:grid-area="text">
			<h2 id="search" class="text-4xl sm:text-5xl font-bold scroll-mt-24">
				<span class="inline-block">Built-In</span>
				<span
					class="inline-block underline decoration-primary/70 underline-offset-4 decoration-dotted"
				>
					Search Bar
				</span>
			</h2>
			<p class="mt-4 mb-3 font-medium text-xl lg:text-2xl prose text-balance">
				Fast, Efficient, and Ready to Go
			</p>
			<p class="mb-6 prose text-balance font-medium">
				Your websites comes with a powerful search bar that instantly indexes all your content.
			</p>
			<div class="flex items-center gap-4 flex-wrap">
				<a
					href="https://docs.easypagego.com/editor/"
					target="_blank"
					class="link link-primary link-hover font-semibold flex items-center gap-1"
				>
					<Icon icon="mdi:books" width="20" height="20" />
					<span> Explore docs </span>
					<Icon icon="ic:sharp-chevron-right" width="20" height="20" />
				</a>
				<div class="tooltip tooltip-top" data-tip="Cooming Soon!">
					<span
						class="link link-accent link-hover font-semibold flex items-center gap-0.5 cursor-wait"
					>
						<span> AI Assistant </span>
						<Icon icon="heroicons:sparkles-20-solid" width="20" height="20" />
					</span>
				</div>
			</div>
		</div>
		<div style:grid-area="media" class="-mx-4 sm:mx-auto max-w-screen-md">
			<div
				class="group bg-gradient-to-tl from-accent to-secondary flex gap-4 items-center p-8 sm:rounded-box"
			>
				<MockPhone class="rounded-box w-1/3">
					<enhanced:img
						src="./search-tablet.png"
						alt="A screenshot of the dashboard representing a codeblock element."
						sizes="(min-width: 1024px) 33.33vw, 100vw"
						class=""
						style="max-width: min(688px, 100%);"
					/>
				</MockPhone>
				<MockTablet class="rounded-box w-2/3">
					<enhanced:img
						src="./search-mobile.png"
						alt="A screenshot of the dashboard representing a codeblock element."
						sizes="(min-width: 1024px) 33.33vw, 100vw"
						class=""
						style="max-width: min(688px, 100%);"
					/>
				</MockTablet>
			</div>
		</div>
		<ul style:grid-area="list" class="flex flex-col gap-4">
			<li class="flex items-center gap-4">
				<div class="w-12 h-12 flex-shrink-0">
					<Icon icon="ic:round-offline-bolt" width="48" height="48" class="text-secondary" />
				</div>
				<div>
					<span class="font-semibold underline underline-offset-2 decoration-secondary"
						>Instant Search</span
					>
					<p class="leading-snug text-base font-medium prose">
						Quickly find what you need with a fast and responsive search function.
					</p>
				</div>
			</li>
			<li class="flex items-center gap-4" data-aos="fade-zoom-in">
				<div class="w-12 h-12 flex-shrink-0">
					<Icon
						icon="ion:cloud-offline-sharp"
						width="48"
						height="48"
						class="text-secondary hue-rotate-15"
					/>
				</div>
				<div>
					<span
						class="font-semibold underline underline-offset-2 decoration-secondary hue-rotate-15"
						>Offline Capability</span
					>
					<p class="leading-snug text-base font-medium prose">
						Fully functional without needing an active API—ideal for static sites.
					</p>
				</div>
			</li>
			<li class="flex items-center gap-4" data-aos="fade-zoom-in">
				<div class="w-12 h-12 flex-shrink-0">
					<Icon icon="mdi:star-box" width="48" height="48" class="text-secondary hue-rotate-30" />
				</div>
				<div>
					<span
						class="font-semibold underline underline-offset-2 decoration-sectext-secondary hue-rotate-30"
						>Prepacked and Simple</span
					>
					<p class="leading-snug text-base font-medium prose">
						Comes with everything needed for seamless deployment. No additional setup required.
					</p>
				</div>
			</li>
		</ul>
	</section>
</div>

<style lang="postcss">
	section {
		@apply grid grid-cols-1 auto-rows-min gap-8;
		grid-template-areas: 'text' 'media' 'list';

		@media (min-width: 768px) {
			@apply grid-cols-2;
			grid-template-areas: 'text list' 'media media';
		}
	}
</style>
