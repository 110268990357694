<script lang="ts">
	import MockWindow from '$lib/components/mocks/MockWindow.svelte';
	import Icon from '@iconify/svelte';

	const guides = [
		{
			id: 'cloudflare',
			title: 'Cloudflare Pages',
			icon: 'skill-icons:cloudflare-light',
			iconSize: { width: 36, height: 36 },
			description: 'Quickly implement and optimize your site with Cloudflare.',
			additionalInfo: 'All it takes is a simple Drag & Drop, or via Wrangler CLI.',
			link: {
				text: 'Wrangler CLI',
				href: 'https://developers.cloudflare.com/workers/wrangler/commands/#deploy-1'
			},
			action: {
				text: 'Read Guide',
				href: 'https://docs.easypagego.com/deployment/how-to-deploy-with-cloudflare-pages/',
				btnClass: 'btn btn-outline md:btn-sm'
			}
		}
		// {
		// 	id: 'github',
		// 	title: 'GitHub Pages',
		// 	icon: 'skill-icons:github-dark',
		// 	iconSize: { width: 36, height: 36 },
		// 	description:
		// 		'Upload it as a repo artifact and manually dispatch that publishes it on GitHub Pages.',
		// 	link: {
		// 		text: 'GitHub Pages',
		// 		href: 'https://pages.github.com/'
		// 	},
		// 	action: {
		// 		text: 'Coming Soon',
		// 		btnClass: 'btn btn-disabled btn-outline md:btn-sm'
		// 	}
		// },
		// {
		// 	id: 'netlify',
		// 	title: 'Netlify',
		// 	icon: 'skill-icons:netlify-light',
		// 	iconSize: { width: 36, height: 36 },
		// 	description: 'Fast and straightforward deployment with automatic updates thanks to Netlify.',
		// 	link: {
		// 		text: 'Netlify',
		// 		href: 'https://www.netlify.com/'
		// 	},
		// 	action: {
		// 		text: 'Coming Soon',
		// 		btnClass: 'btn btn-disabled btn-outline md:btn-sm'
		// 	}
		// },
		// {
		// 	id: 'docker',
		// 	title: 'Self Host with Docker',
		// 	icon: 'skill-icons:docker',
		// 	iconSize: { width: 36, height: 36 },
		// 	description: 'Containerize and deploy your site effortlessly.',
		// 	action: {
		// 		text: 'Coming Soon',
		// 		btnClass: 'btn btn-disabled btn-outline md:btn-sm'
		// 	}
		// }
	];
	const { action, additionalInfo, description, icon, iconSize, id, link, title } = guides[0];
</script>

<div
	class="bg-gradient-to-b from-transparent to-base-300 pb-6 shadow-2xl shadow-primary/5 border-b border-primary/10 px-4 sm:pb-16 md:pb-20"
>
	<section class="container mx-auto">
		<div style:grid-area="text" class="prose">
			<h2 id="deploy" class="mb-2 text-4xl sm:text-5xl font-bold scroll-mt-24">Easy Deployment</h2>
			<p class="mt-4 mb-6 text-xl lg:text-2xl font-semibold text-balance">
				Click and <span class="underline decoration-secondary decoration-dotted">Download</span>
			</p>
			<div class="mb-6 prose text-balance font-medium">
				<p>
					With a single button press, your <em>optimized</em> site will be ready for download in no time.
				</p>
				<p>
					It comes fully packaged with everything it needs to run, including <a
						href="/#search"
						class="link-hover link-primary">built-in search</a
					>
					functionality across all your content, without any additional setup.
				</p>
			</div>
			<div class="flex items-center gap-4 flex-wrap">
				<a
					href="https://docs.easypagego.com/deployment/how-to-deploy-with-cloudflare-pages/"
					target="_blank"
					class="link link-accent link-hover font-semibold flex items-center gap-1"
				>
					<Icon {icon} width="20" height="20" class="border border-base-300 shadow-xl rounded-md" />
					<span> Deploy to Cloudflare Pages </span>
				</a>
				<a
					href="https://docs.easypagego.com/deployment/"
					target="_blank"
					class="link link-primary link-hover font-semibold flex items-center gap-1"
				>
					<Icon icon="mdi:books" width="20" height="20" />
					<span> Explore docs </span>
					<Icon icon="ic:sharp-chevron-right" width="20" height="20" />
				</a>
			</div>
		</div>
		<div style:grid-area="card" class="hidden lg:flex flex-col-reverse items-center xl:-mt-8">
			<article
				class="w-11/12 bg-gradient-to-b from-base-300 to-base-200/50 card card-compact card-bordered shadow-2xl shadow-secondary/5 border-x-secondary/5 border-b-secondary/15 xl:rounded-t-none"
			>
				<div class="card-body">
					<h3 id={`deploy-${id}`} class="card-title scroll-mt-40">
						<Icon {icon} width={iconSize.width} height={iconSize.height} />
						<span>{title}</span>
					</h3>
					<p>{description}</p>
					{#if additionalInfo}
						<p>
							{additionalInfo}
							{#if link}
								<a href={link.href} target="_blank" rel="noopener noreferrer" class="link">
									{link.text}
								</a>
							{/if}
						</p>
					{/if}
					<div class="mt-2 card-actions justify-end">
						{#if action.href}
							<a
								href={action.href}
								class={action.btnClass}
								target="_blank"
								rel="noopener noreferrer"
							>
								{action.text}
							</a>
						{:else}
							<span class={action.btnClass}>{action.text}</span>
						{/if}
					</div>
				</div>
			</article>
		</div>
		<div style:grid-area="video" class="flex flex-col items-center xl:justify-end">
			<MockWindow class="shadow-xl shadow-white/5">
				<video
					width="1200"
					height="960"
					autoplay
					loop
					playsinline
					muted
					class="bg-base-300 rounded-b-box"
				>
					<source src="/videos/deploy.mp4" type="video/mp4" />
					Your browser does not support the video tag.
				</video>
			</MockWindow>
		</div>
		<ul style:grid-area="list" class="mt-4 md:mt-0 flex flex-col gap-4">
			<li class="flex items-center gap-4">
				<div
					class="w-12 h-12 flex-shrink-0 grid place-content-center border-2 border-secondary bg-secondary/10 shadow-inner rounded-full"
				>
					<div class="loading loading-lg loading-ring text-secondary"></div>
				</div>
				<div>
					<span class="font-semibold underline underline-offset-2 decoration-secondary">
						500 Builds/month
					</span>
					<p class="leading-snug text-base font-medium prose text-balance">
						Generate your site up to 500 times a month. Experiment and refine as needed without
						restrictions.
					</p>
				</div>
			</li>
			<li class="flex items-center gap-4">
				<div class="w-12 h-12 flex-shrink-0">
					<Icon
						icon="mdi:unlocked-check"
						width="48"
						height="48"
						class="text-secondary hue-rotate-15"
					/>
				</div>
				<div>
					<span
						class="font-semibold underline underline-offset-2 decoration-secondary hue-rotate-15"
					>
						No Lock-In, No Limits
					</span>
					<p class="leading-snug text-base font-medium prose text-balance">
						Your website is a true static site, giving you the freedom to move and deploy without
						being tied to any specific platform.
					</p>
				</div>
			</li>
		</ul>
		<div style:grid-area="cta">
			<a
				href="/#pricing"
				class="btn btn-lg btn-block md:btn-wide btn-primary shadow-lg shadow-primary/10"
			>
				<Icon icon="solar:rocket-bold-duotone" width="32" height="32" />
				<span> Get started </span>
			</a>
		</div>
	</section>
</div>

<style lang="postcss">
	section {
		@apply grid grid-cols-1 auto-rows-auto gap-8;
		grid-template-areas: 'text' 'video' 'list' 'cta';

		@media (min-width: 768px) {
			@apply grid grid-cols-1;
		}

		@media (min-width: 1024px) {
			@apply grid grid-cols-[2fr_1fr];
			grid-template-areas: 'text text' 'video card' 'list cta';
		}

		@media (min-width: 1280px) {
			@apply grid grid-cols-2;
			grid-template-areas: 'text video' 'list video' 'cta card';
		}
	}
</style>
