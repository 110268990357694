<script lang="ts" context="module">
	declare global {
		interface Window extends WindowWithLemonsqueezy {}
	}

	interface WindowWithLemonsqueezy {
		createLemonSqueezy: () => void;
		LemonSqueezy: {
			/**
			 * Initialises Lemon.js on your page.
			 * @param options - An object with a single property, eventHandler, which is a function that will be called when Lemon.js emits an event.
			 */
			Setup: (options: { eventHandler: (event: { event: string }) => void }) => void;
			/**
			 * Refreshes `lemonsqueezy-button` listeners on the page.
			 */
			Refresh: () => void;

			Url: {
				/**
				 * Opens a given Lemon Squeezy URL, typically these are Checkout or Payment Details Update overlays.
				 * @param url - The URL to open.
				 */
				Open: (url: string) => void;

				/**
				 * Closes the current opened Lemon Squeezy overlay checkout window.
				 */
				Close: () => void;
			};
			Affiliate: {
				/**
				 * Retrieve the affiliate tracking ID
				 */
				GetID: () => string;

				/**
				 * Append the affiliate tracking parameter to the given URL
				 * @param url - The URL to append the affiliate tracking parameter to.
				 */
				Build: (url: string) => string;
			};
		};
	}
</script>

<script lang="ts">
	import { browser, dev } from '$app/environment';
	import { onMount } from 'svelte';
	import Icon from '@iconify/svelte';
	import { to } from '$lib/utils/promise';
	import { PUBLIC_DASHBOARD_BASE_URL } from '$env/static/public';

	let ready = false;
	if (browser)
		onMount(() => {
			const id = setInterval(() => {
				if (window.LemonSqueezy) {
					clearInterval(id);
					window.createLemonSqueezy();
					if (dev)
						window.LemonSqueezy.Setup({
							eventHandler(event) {
								console.info(event);
							}
						});
					ready = true;
					return;
				}
			}, 100);

			return () => clearInterval(id);
		});

	async function getCheckout() {
		alert('We are coming!');

		return;
		// const url = new URL('/api/lemonsqueezy/checkout/', PUBLIC_DASHBOARD_BASE_URL);
		// const res = await to(fetch(url));
		// if (res.err) {
		// 	console.error(res.err);
		// 	// TODO toast
		// 	return;
		// }

		// const data = await to(res.data.json());
		// if (data.err) {
		// 	console.error(res.err);
		// 	// TODO toast
		// 	return;
		// }

		// if (!data.data.data.checkoutURL) {
		// 	console.error('Unexpected payload');
		// 	// TODO toast
		// 	return;
		// }

		// window.LemonSqueezy.Url.Open(data.data.data.checkoutURL);
	}
</script>

<svelte:head>
	<script src="https://app.lemonsqueezy.com/js/lemon.js" defer></script>
</svelte:head>

<section class="relative">
	<div
		aria-hidden="true"
		class="absolute top-0 left-0 right-0 h-screen w-[200vw] -translate-x-1/2 fade-y -z-50"
	>
		<svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
			<defs>
				<pattern id="dotPattern" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
					<circle class="fill-secondary" cx="5" cy="5" r="10"></circle>
				</pattern>
			</defs>
			<rect x="0" y="0" width="100%" height="100%" fill="url(#dotPattern)"></rect>
		</svg>
	</div>
	<header class="text-center">
		<h2 id="pricing" class="text-4xl sm:text-5xl font-bold scroll-mt-24 text-primary">Pricing</h2>
		<p class="mt-4 mb-3 font-medium text-3xl lg:text-4xl prose sm:mx-auto text-balance">
			Get it <span class="underline underline-offset-4 decoration-primary decoration-dotted"
				>Done</span
			>
		</p>
	</header>
	<div class="mt-10 flex flex-col items-center gap-2">
		<article
			class="card bg-gradient-to-tr from-base-100/30 to-base-100/5 backdrop-blur-[2px] border-2 border-dashed border-primary/50 max-w-md shadow-lg shadow-primary/10"
		>
			<div class="card-body">
				<div class="mb-6 text-center">
					<div class="mb-3 text-4xl font-black">Full Access</div>
					<p class="text-2xl font-semibold">Get the full experience.</p>
				</div>
				<span class="flex items-center justify-center gap-3">
					<s class="font-bold text-2xl"> 60$ </s>
					<Icon icon="line-md:chevron-small-triple-right" width="36" height="36" />
					<div class="flex flex-col items-center">
						<strong class="font-black text-5xl text-primary underline underline-offset-8">
							40$
						</strong>
						<span class="mt-1 font-semibold text-xl text-primary/80"> month </span>
					</div>
				</span>
				<ul class="mt-3 list-none list-inside text-lg flex flex-col gap-2">
					<li class="flex items-center gap-2">
						<Icon icon="bi:patch-check-fill" width="24" height="24" class="text-primary" />
						<span> Access to the dashboard </span>
					</li>
					<li class="flex items-center gap-2">
						<Icon icon="bi:patch-check-fill" width="24" height="24" class="text-primary" />
						<span> 500 Builds/month </span>
					</li>
					<li class="flex items-center gap-2">
						<Icon icon="bi:patch-check-fill" width="24" height="24" class="text-primary" />
						<span> Customer Support </span>
					</li>
				</ul>
				<!-- DEV: Test cards <https://docs.lemonsqueezy.com/help/getting-started/test-mode#test-card-numbers> -->
				<button
					disabled={!ready}
					type="button"
					on:click={getCheckout}
					class="mt-6 btn btn-block btn-primary btn-lg"
				>
					{#if !ready}
						<span class="loading loading-spinner"></span>
					{:else}
						<Icon icon="solar:rocket-bold-duotone" width="28" height="28" />
					{/if}
					<span class="text-lg sm:text-2xl"> Get your Docs </span>
				</button>
				<p class="mt-2 text-sm prose text-center">
					Payments are processed via an in-site popup through <a
						href="https://www.lemonsqueezy.com/"
						target="_blank"
						rel="noopener noreferrer"
						class="link link-hover inline-flex items-center gap-0.5"
					>
						<Icon icon="simple-icons:lemonsqueezy" width="10" height="10" />
						<span> LemonSqueezy </span>
					</a>.
				</p>
			</div>
		</article>
	</div>
</section>

<style lang="postcss">
	section {
		@apply px-3 container max-w-screen-xl mx-auto;
	}

	.fade-y {
		-webkit-mask-image: linear-gradient(
			to bottom,
			rgba(0, 0, 0, 0),
			rgba(0, 0, 0, 1),
			rgba(0, 0, 0, 0)
		);
		mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
	}
</style>
