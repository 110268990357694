<script lang="ts">
	import { browser } from '$app/environment';
	import MockTablet from '$lib/components/mocks/MockTablet.svelte';
	import MockWindow from '$lib/components/mocks/MockWindow.svelte';
	import Icon from '@iconify/svelte';
	import { onMount } from 'svelte';

	const viewports = ['ion:desktop', 'ion:tablet-portrait', 'ion:phone-portrait'];
	let activeIndexViewport = 0;
	$: activeViewport = viewports[activeIndexViewport];

	if (browser)
		onMount(() => {
			const intervalId = setInterval(updateViewport, 1500);
			return () => clearInterval(intervalId);
		});
	function updateViewport() {
		activeIndexViewport = (activeIndexViewport + 1) % viewports.length;
	}
</script>

<div
	class="bg-gradient-to-b from-transparent to-base-300 shadow-2xl shadow-primary/5 border-b border-primary/10 px-4 pb-8"
>
	<section class="-mx-4 sm:container sm:mx-auto">
		<div style:grid-area="text" class="px-4">
			<h2 id="editor" class="text-4xl sm:text-5xl font-bold scroll-mt-24">
				<span class="inline-block">Powerful</span>
				<span
					class="inline-block underline decoration-primary/70 underline-offset-4 decoration-dotted"
				>
					No-Code Editor
				</span>
			</h2>
			<p class="mt-4 mb-3 font-medium text-xl lg:text-2xl prose text-balance">
				Create with Confidence
			</p>
			<p class="mb-6 prose text-balance font-medium">
				A full no-code experience that lets you build stunning documentation websites, effortlessly.
			</p>
			<a
				href="https://docs.easypagego.com/editor/"
				target="_blank"
				class="link link-primary link-hover font-semibold flex items-center gap-1"
			>
				<Icon icon="mdi:books" width="20" height="20" />
				<span> Explore docs </span>
				<Icon icon="ic:sharp-chevron-right" width="20" height="20" />
			</a>
		</div>
		<div
			style:grid-area="media"
			class="isolate sm:mb-0 w-screen sm:w-full mx-auto grid gap-0.5 grid-cols-1 sm:grid-cols-2 sm:gap-2 lg:grid-cols-[3fr_2fr]"
		>
			<div
				class="bg-gradient-to-tr from-primary/80 to-primary relative h-[60vw] sm:h-auto shadow-inner sm:rounded-box overflow-hidden"
			>
				<div
					class="absolute inset-0 bg-gradient-to-t from-transparent to-secondary/70 aspect-square"
				></div>
				<MockTablet
					class="w-4/6 sm:w-5/6 lg:w-4/6 xl:w-8/12 aspect-ratio-[4/3] absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 bg-base-300"
				>
					<video
						width="1280"
						height="960"
						autoplay
						loop
						playsinline
						muted
						class="transition-shadow bg-base-300"
					>
						<source
							src="/videos/dynamic-preview-970x720.mp4"
							type="video/mp4"
							media="(max-width: 768px)"
						/>
						<source
							src="/videos/dynamic-preview-1280x960.mp4"
							type="video/mp4"
							media="(min-width: 769px)"
						/>
						Your browser does not support the video tag.
					</video>
				</MockTablet>
			</div>
			<div
				class="bg-gradient-to-br from-accent/50 via-secondary to-accent/60 relative shadow-inner sm:rounded-box overflow-hidden"
			>
				<div class="w-11/12 h-[60vw] aspect-video sm:aspect-square sm:h-auto">
					<enhanced:img
						src="./codeblock.png?w=1024;640;400"
						alt="A screenshot of the dashboard representing a codeblock element."
						sizes="(min-width: 1024px) 33.33vw, 100vw"
						class="z-0 mx-auto bg-cover rounded-box w-8/12 sm:w-9/12 absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 bg-base-300 shadow-xl shadow-white/20"
						style="max-width: min(688px, 100%);"
					/>
				</div>
			</div>
		</div>
		<ul style:grid-area="list" class="px-4 flex flex-col gap-4">
			<li class="flex items-center gap-4">
				<div class="w-12 h-12 flex-shrink-0">
					<Icon
						icon="svg-spinners:blocks-shuffle-3"
						width="48"
						height="48"
						class="text-secondary"
					/>
				</div>
				<div>
					<span class="font-semibold underline underline-offset-2 decoration-secondary">
						Flexible Building Blocks
					</span>
					<p class="leading-snug text-base font-medium prose">
						Add, remove, or rearrange components as needed—no coding required.
					</p>
				</div>
			</li>
			<li class="flex items-center gap-4" data-aos="fade-zoom-in">
				<div class="w-12 h-12 flex-shrink-0">
					<Icon
						icon="bi:lightbulb-fill"
						width="48"
						height="48"
						class="text-secondary hue-rotate-15"
					/>
				</div>
				<div>
					<span
						class="font-semibold underline underline-offset-2 decoration-secondary hue-rotate-15"
					>
						User-Friendly Design
					</span>
					<p class="leading-snug text-base font-medium prose">
						Crafted for ease of use, even without technical skills.
					</p>
				</div>
			</li>
			<li class="flex items-center gap-4">
				<button type="button" on:click={updateViewport}>
					<span class="sr-only">Easter Egg</span>
					<Icon icon={activeViewport} width="48" height="48" class="text-secondary hue-rotate-15" />
				</button>
				<div>
					<span
						class="font-semibold underline underline-offset-2 decoration-secondary hue-rotate-15"
					>
						Real-Time Previews
					</span>
					<p class="leading-snug text-base font-medium prose">
						Instantly see how your page looks on desktop, tablet, and mobile. What you see is truly
						what you get.
					</p>
				</div>
			</li>
		</ul>
	</section>
</div>

<style lang="postcss">
	section {
		@apply grid grid-cols-1 auto-rows-auto gap-6;
		grid-template-areas: 'text' 'media' 'list';
		@media (min-width: 768px) {
			@apply grid grid-cols-2 auto-rows-auto gap-8;
			grid-template-areas: 'text list' 'media media';
		}
	}
</style>
