<script lang="ts">
	import { cn } from '$lib/utils/style';

	let className = '';
	export { className as class };
</script>

<div class={cn('rounded-box border-4 border-neutral ring-2 overflow-hidden shadow-xl shadow-white/20 bg-base-200', className)}>
	<slot />
</div>
