<svelte:options immutable />

<script lang="ts">
	import { codeToHtml, type BundledLanguage, type BundledTheme } from 'shiki';
	import { onMount } from 'svelte';
	import { browser } from '$app/environment';
	import { cn } from '$lib/utils/style';

	let className = '';
	export { className as class };
	export let code: string;
	export let lang: BundledLanguage;
	export let theme: BundledTheme;

	let ref: HTMLDivElement;

	onMount(async () => render());
	$: if (browser && ref) render(), theme;

	async function render(): Promise<void> {
		ref.innerHTML = await codeToHtml(code, {
			lang: lang,
			theme,
			transformers: [
				{
					pre(hast) {
						this.addClassToHast(
							hast,
							`m-0 p-4 h-full overflow-x-auto text-sm font-mono leading-6 tracking-normal rounded-box`
						);
					}
				}
			]
		});
	}
</script>

<div bind:this={ref} class={cn('relative w-full h-min', className)}>
	<div class="sr-only">{code}</div>
	<span
		aria-hidden="true"
		class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-sm font-medium opacity-70 flex items-center gap-2"
	>
		<span class="loading loading-spinner"></span>
		Rendering codeblock...
	</span>
</div>
