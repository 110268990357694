<script lang="ts">
	import MockPhone from "$lib/components/mocks/MockPhone.svelte";
	import MockWindow from '$lib/components/mocks/MockWindow.svelte';
	import Icon from '@iconify/svelte';
</script>

<div
	class="bg-gradient-to-b from-transparent to-primary/5 pb-6 shadow-2xl shadow-primary/5 border-b border-primary/10 px-4 sm:pb-16 md:pb-20"
>
	<section class="mx-auto md:container">
		<div style:grid-area="text">
			<h2 id="text-formatting" class="text-4xl sm:text-5xl font-bold scroll-mt-24">
				<span
					class="inline-block underline decoration-secondary/70 underline-offset-4 decoration-dotted"
				>
					Enriched
				</span>
				<span class="inline-block">Text Formatting</span>
			</h2>
			<p class="mt-4 mb-6 text-xl lg:text-2xl font-semibold text-balance">For a Polished look</p>
			<div class="mb-6 prose text-balance font-medium">
				<p>
					Create professional with a full suite of text formatting tools like <strong>bold</strong>,
					<strong>italic</strong>, <strong>underline</strong>, <strong>quotes</strong>,
					<strong>headings</strong>, <strong>lists</strong> and much more!
				</p>
			</div>
		</div>
		<div
			style:grid-area="media"
			class="-mx-4 w-screen md:w-full grid grid-cols-[7fr_4fr] gap-0.5 sm:gap-2 md:rounded-box overflow-hidden"
		>
			<div class="group bg-gradient-to-bl from-success to-secondary pl-2 sm:pl-6 lg:pl-14 pt-8 rounded-tr-box overflow-hidden">
				<MockWindow
					class="shadow-xl shadow-white/20 rounded-t-none translate-x-4 translate-y-4 group-hover:-translate-y-0 group-hover:-translate-x-0 transition-transform will-change-transform duration-1000 ease-in-out"
					classBar="rounded-t-box"
				>
					<enhanced:img
						src="./typography.png?quality=100&lossless=false"
						sizes="(min-width: 1024px) 50vw, 100vw"
						alt="A screenshot of the dashboard representing a codeblock element."
						class="bg-cover w-full rounded-bl-box"
					/>
				</MockWindow>
			</div>
			<div
				class="group bg-gradient-to-br from-accent via-accent to-secondary relative rounded-tl-box"
			>
				<MockPhone class="w-9/12 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 group-hover:-translate-y-[51%] group-hover:-translate-x-[52%] transition-transform will-change-transform duration-1000 ease-in-out rounded-box sm:rounded-[3rem]">
					<enhanced:img
						src="./typography-dark.png?quality=100&lossless=false"
						sizes="(min-width: 1024px) 50vw, 100vw"
						alt="A screenshot of the dashboard representing a codeblock element."
						class="bg-cover w-full h-full"
					/>
				</MockPhone>
			</div>
		</div>
		<ul style:grid-area="list" class="flex flex-col gap-4">
			<li class="flex items-center gap-4">
				<div class="w-12 h-12 flex-shrink-0">
					<Icon icon="mdi:send-secure" width="48" height="48" class="text-secondary" />
				</div>
				<div>
					<span class="font-semibold underline underline-offset-2 decoration-secondary">
						Secure External Links
					</span>
					<p class="leading-snug text-base font-medium prose">
						External links automatically include <code>rel="noopener noreferrer"</code> for added security.
					</p>
				</div>
			</li>
			<li class="flex items-center gap-4">
				<div class="w-12 h-12 flex-shrink-0">
					<Icon
						icon="material-symbols:tab-new-right-rounded"
						width="48"
						height="48"
						class="text-secondary hue-rotate-15"
					/>
				</div>
				<div>
					<span
						class="font-semibold underline underline-offset-2 decoration-secondary hue-rotate-15"
					>
						Keep Readers Engaged
					</span>
					<p class="leading-snug text-base font-medium prose">
						External links open in new tabs, keeping visitors on your site.
					</p>
				</div>
			</li>
			<li class="flex items-center gap-4">
				<div class="w-12 h-12 flex-shrink-0">
					<Icon
						icon="solar:link-circle-bold"
						width="48"
						height="48"
						class="text-secondary hue-rotate-30"
					/>
				</div>
				<div>
					<span
						class="font-semibold underline underline-offset-2 decoration-secondary hue-rotate-30"
						>Smart Internal Links</span
					>
					<p class="leading-snug text-base font-medium prose">
						Rename or move pages without worrying about broken links—everything stays connected.
					</p>
				</div>
			</li>
		</ul>
	</section>
</div>

<style lang="postcss">
	section {
		@apply grid grid-cols-1 auto-rows-auto gap-8;
		grid-template-areas: 'text' 'media' 'list';
		@media (min-width: 1024px) {
			@apply grid-cols-2;
			grid-template-areas: 'text list' 'media media';
		}
	}
</style>
